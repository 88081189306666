<template>
  <div
    class="sm:px-10 sm:pt-12 sm:pb-10 flex flex-col text-center items-center justify-center"
  >
    <h1 class="text-xl text-grayscale-96 leading-160 font-druk-wide pt-16">
      Log in to Xposure
    </h1>

    <p class="mt-6 text-manatee text-sm font-medium">
      Don’t have an account?
      <button
        class="text-blue-haze hover:text-accent-orange transition-all ease-in-out duration-200 p-2.5"
        @click="goSignup"
      >
        Create account
      </button>
    </p>

    <div class="w-full mt-14 flex justify-between">
      <div class="w-full flex">
        <SocialLoginButton
          :loginType="SocialLoginTypes.google"
          @onSocialClick="onSocialClick"
        />
        <!-- <SocialLoginButton
            :loginType="SocialLoginTypes.twitter"
            @onSocialClick="onSocialClick"
          /> -->
      </div>
    </div>

    <p class="mt-6 text-manatee text-13 leading-160">Or</p>

    <form class="w-full mt-6">
      <input-field
        v-model:value="email"
        name="email"
        placeholder="Email"
        type="text"
        :is-disabled="isLoading"
        v-model:error-message="errors.emailError"
        @clearError="clearError"
        @proceed="checkInput"
      />
      <input-field
        v-model:value="password"
        name="password"
        placeholder="Password"
        type="password"
        class="mt-2"
        :is-disabled="isLoading"
        v-model:error-message="errors.passwordError"
        @clearError="clearError"
        @proceed="checkInput"
      />
    </form>
    <span v-if="errorMessage" class="text-accent-red text-13">{{
      errorMessage
    }}</span>
    <button
      class="mt-2 text-manatee text-13 font-medium ml-auto hover:text-accent-orange transition-all ease-in-out duration-200"
      @click="goForgotPassword"
    >
      Forgot password?
    </button>
    <general-button @click="checkInput" class="w-full mt-8">
      Log in
    </general-button>
  </div>
</template>

<script setup lang="ts">
import GeneralButton from "@/components/layout/buttons/GeneralButton.vue";
import { useAuthenticationStore } from "@/store/authentication";
import {
  AuthActionTypes,
  SignUpError,
  SocialLoginTypes,
} from "@/types/authentication";
import ApiError from "@/types/error";
import { runWithDelay, scrollToFirstError } from "@/utils/helpers";
import { validateEmail, validatePassword } from "@/utils/validation";
import { reactive, ref } from "vue";
import SocialLoginButton from "../../components/layout/buttons/SocialLoginButton.vue";
import InputField from "../../components/layout/inputs/InputField.vue";
import { useLocalNotificationsStore } from "@/store/notifications";

const emit = defineEmits(["changeAuthType", "userAuthenticated"]);
const errors = reactive({
  emailError: "",
  passwordError: "",
} as SignUpError);
const email = ref("");
const password = ref("");
const isLoading = ref(false);
const errorMessage = ref("");

const authenticationStore = useAuthenticationStore();
const localNotifications = useLocalNotificationsStore();

const checkInput = async (): Promise<void> => {
  isLoading.value = true;
  errors.emailError = validateEmail(email.value);
  errors.passwordError = validatePassword(password.value);

  if (errors.emailError || errors.passwordError) {
    scrollToFirstError(errors);
    isLoading.value = false;
    return;
  }

  try {
    await authenticationStore.login(email.value, password.value);
    emit("userAuthenticated");
    authenticationStore.setModalState({
      isModalVisible: false,
    });
  } catch (error) {
    errorMessage.value = (error as ApiError).message;
    runWithDelay(() => (errorMessage.value = ""));
  }

  isLoading.value = false;
};

const clearError = (inputName: string): void => {
  errors[`${inputName}Error` as keyof SignUpError] = "";
};

const googleLogin = async () => {
  try {
    const { url } = await authenticationStore
      .getAuthService()
      .getGoogleLoginUrl();
    if (!process.server) window.location.href = url;
    authenticationStore.setModalState({
      isModalVisible: false,
    });
  } catch (error: any) {
    localNotifications.addNotification({
      message: error.message,
      isError: true,
    });
  }
};

const twitterLogin = async () => {
  try {
    const { url, verifier } = await authenticationStore.getTwitterLoginUrl();
    localStorage.setItem("twitter_code_verifier", verifier);
    if (!process.server) window.location.href = url;
  } catch (error: any) {
    localNotifications.addNotification({
      message: error.message,
      isError: true,
    });
  }
};

const onSocialClick = (type: SocialLoginTypes) => {
  const actionsBySocialType = {
    [SocialLoginTypes.google]: googleLogin,
    [SocialLoginTypes.twitter]: twitterLogin,
  };
  try {
    actionsBySocialType[type]?.();
  } catch (error) {
    errorMessage.value = (error as ApiError).message;
    runWithDelay(() => (errorMessage.value = ""));
  }
};

const goSignup = () => {
  emit("changeAuthType", AuthActionTypes.signup);
};

const goForgotPassword = () => {
  emit("changeAuthType", AuthActionTypes.forgotPassword);
};
</script>
